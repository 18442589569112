import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

// director images
import ridhwana from "../images/directors/ridhwana.jpg"
import fanelwa from "../images/directors/fanelwa.jpg"
import emily from "../images/directors/emily.jpg"
import danai from "../images/directors/danai.jpg"
import chiedza from "../images/directors/chiedza.jpg"
import tutor1 from "../images/tutors/tutor1.jpg"
import tutor2 from "../images/tutors/tutor2.png"
import tutor3 from "../images/tutors/tutor3.jpg"
import tutor4 from "../images/tutors/tutor4.png"
import tutor5 from "../images/tutors/tutor5.jpg"
import tutor6 from "../images/tutors/tutor6.jpg"

const TeamPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

    <div className="container team__container">
      <h1 className="container__header"> Our Leadership Team </h1>
      <div className="team team--founders">
        <div className="team__member">
          <img className="team__member-image" src={ridhwana} alt="Ridhwana"/>
          <div className="team__member-name">Ridhwana Khan</div>
          <div className="team__member-short-description">
            Software Engineeer with a Bachelor of Science Degree, majoring in Computer Science and Applied Mathematics
          </div>
          <div className="team__member-description">
            <p>She is the co-founder of a software development company, Zero One Bespoke Software Development. As a software developer she is constantly fulfilling her passion of finding solutions to problems that meaningfully impact people's lives.</p>
            <p>Ridhwana is also a co-organizer of a Ladies that UX meetup group that serves a support system for women to learn more about UX and to build meaningful relationships.</p>
            <p>Ridhwana earned a Bachelor of Science Degree, majoring in Computer Science and Applied Mathematics.</p>
          </div>
        </div>

        <div className="team__member">
          <img className="team__member-image" src={fanelwa} alt="Fanelwa"/>
          <div className="team__member-name">Fanelwa Ajayi</div>
          <div className="team__member-short-description">
              Senior Lecturer with a PhD in Physical and Electrochemical Chemistry
          </div>
          <div className="team__member-description">
            <p>Dr. Fanelwa Ajayi is an academic and a social entrepreneur. She is a senior lecturer in Physical Chemistry at the University of the Western Cape where she is also involved in postgraduate student project supervision. Through her work Fanelwa collaborates extensively locally and internationally in the field of electrochemical drug metabolism nanobiosensors.
            </p>
            <p>Fanelwa is also the founder of AmaQawe ngeMfundo a STEM promotion and enrichment non-profit organisation. She is passionate about STEM education amongst children in marginalised communities. Her message is delivered through a series of motivational talks at school in such communities in the Cape Flats of the Western Cape. Fanelwa holds a PhD in Physical and Electrochemical Chemistry from the University of the Western Cape.
            </p>
          </div>
        </div>

        <div className="team__member">
          <img className="team__member-image" src={emily} alt="Emily"/>
          <div className="team__member-short-description">
            <div className="team__member-name">Emily Anne Pather</div>
              Head of DevOps with a Computer Engineering Degree
          </div>
          <div className="team__member-description">
              <p>Emily Pather is currently Head of DevOps at Torch TLM and the founder of a technology start-up called Virtuo Labs.</p>
              <p>She is a Computer Engineer and has been involved in technical development since 2006.</p>
              <p>Emily has experience in software development, telecommunications and data analytics. During all her years of work she has served as a mentor and as the branch chair-person of the her companyâ€™s engineering society.</p>
              <p>Emily has a passion for innovation and technology and she loves using that passion to solve real-world challenges.</p>
          </div>
        </div>

        <div className="team__member">
          <img className="team__member-image" src={danai} alt="Danai"/>
          <div className="team__member-name"> Danai Nhando </div>
          <div className="team__member-short-description">
            EdTech Specialist with a Bachelor of Commerce Degree in Law and Economics and an LLB
          </div>
          <div className="team__member-description">
            <p>Danai Nhando is a Human Rights Lawyer and EdTech Specialist.</p>
            <p>She is the Co-Founder of Ampli5yd, an EdTech Learning and Development Consultancy. Ampli5yd is the organiser of the Future of Learning Conference and MeetUps focused on crafting solutions to address the most intractable challenges facing African education systems.</p>
            <p>A TEDx Pretoria 2016 speaker, Danai is a published author with eLearning Industry, Mail and Guardian and Huffington Post. </p>
            <p>Danai holds a Bachelor of Commerce Degree in Law and Economics and an LLB from Rhodes University, as well as an MBA from University of London, Royal Holloway.</p>
          </div>
        </div>

        <div className="team__member">
          <img className="team__member-image" src={chiedza} alt="Chiedza"/>
          <div className="team__member-name">Chiedza Mnguni</div>
          <div className="team__member-short-description">
            Civil Engineer with a Diploma Civil Engineering, BTech Degree in Urban Engineering, a BSc Hons in Transportation Planning and an MBA
          </div>
          <div class="team__member-description">
            <p>Chiedza is a professional Civil Engineering Technologist with an MBA and is also an Entrepreneur.</p>
            <p>In her role as a project manager infrastructure she manages the implementation of community infrastructure projects, in mining communities. She is also the founder of Dress Afrique, an afro-chic clothing company.</p>
            <p>Her passion for motivating young females in engineering motivated her dissertation topic: Challenges of Retention: female civil engineers in consulting firms. She enjoys motivating and encouraging young girls to pursue careers in engineering.</p>
            <p>Chiedzaâ€™s qualifications include; National Diploma in Civil Engineering, BTech Degree in Urban Engineering a BSc Hons in Transportation Planning and an MBA.</p>
          </div>
        </div>
      </div>
    </div>

    <div className="container team__container">
      <h1 className="container__header"> Our Superstar Tutors </h1>

      <div className="team team--volunteers">
        <div className="team__member">
          <img className="team__member-image" src={tutor1} alt="Puseletso"/>
          <div className="team__member-name">Puseletso</div>
          <div className="team__member-short-description">
          </div>
          <div class="team__member-description">
          </div>
        </div>

        <div className="team__member">
          <img className="team__member-image" src={tutor2} alt="Kgotso"/>
          <div className="team__member-name">Kgotso</div>
          <div className="team__member-short-description">
          </div>
          <div class="team__member-description">
          </div>
        </div>

        <div className="team__member">
          <img className="team__member-image" src={tutor3} alt="Kgotso"/>
          <div className="team__member-name">Ronald</div>
          <div className="team__member-short-description">
          </div>
          <div class="team__member-description">
          </div>
        </div>

        <div className="team__member">
          <img className="team__member-image" src={tutor4} alt="Kgotso"/>
          <div className="team__member-name">Wise</div>
          <div className="team__member-short-description">
          </div>
          <div class="team__member-description">
          </div>
        </div>

        <div className="team__member">
          <img className="team__member-image" src={tutor5} alt="Fortunate"/>
          <div className="team__member-name">Fortunate</div>
          <div className="team__member-short-description">
          </div>
          <div class="team__member-description">
          </div>
        </div>

        <div className="team__member">
          <img className="team__member-image" src={tutor6} alt="Teboho"/>
          <div className="team__member-name">Teboho</div>
          <div className="team__member-short-description">
          </div>
          <div class="team__member-description">
          </div>
        </div>

      </div>


    </div>
  </Layout>
)

export default TeamPage
